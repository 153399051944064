body {
  padding-right: 0 !important;
}
.ctm-bkg-senator {
  background: #4b575f;
  /* background-color: #eeeeee; */
}
.ctm-bkg-personaltotal {
  background: #2e2e2e;
  /* background-color: #eeeeee; */
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  color: #092455;
  overflow-y: auto !important;
}
.text-transform-none {
  text-transform: none !important;
}
#loading-indicator-overlay {
  position: absolute;
  z-index: 10000000;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(50, 50, 50, 0.8);
}

.document-upload-icon {
  display: block;
  text-align: center;
  font-size: 100px;
  color: #092455;
}

#loading-indicator-container {
  position: absolute;
  top: 35%;
  left: 50%;
  width: 100px;
  margin-left: -50px;
  height: 100px;
  color: #39a9dc;
}
#container-body {
  min-height: 100vh;
  position: relative;
}
#body {
  padding-top: 110px;
  padding-bottom: 60px;
}
#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
}

.login-paper {
  margin-top: 30px;
  margin-bottom: 30px;
}
